<!--
 * @Date: 2021-03-03 20:12:59
 * @LastEditors: frank
 * @LastEditTime: 2021-04-01 19:32:20
 * @FilePath: /shop_frontend/src/views/pageDecoration/carousel.vue
 * @Description: Description
-->
<template>
  <section class="carousel">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="speed" label="轮播速度" prop="speed">
        <a-input v-model="form.speed" class="w220" />
        <span>&nbsp;秒</span>
      </a-form-model-item>
      <a-form-model-item
        label="轮播图"
        v-for="(item, index) in form.panel_items"
        :key="index"
      >
        <a-row>
          <a-col span="24">
            <a-upload
              name="image"
              list-type="picture-card"
              :show-upload-list="false"
              action="/api/carousel_pictures/"
              :withCredentials="true"
              :headers="headers"
              @change="uploadChange(item, index, $event)"
                                :beforeUpload="beforeUpload"
            >
              <img
                style="display: inline-block; width: 375px; height: 210px"
                v-if="item.picture_url"
                :src="item.picture_url"
              />
              <div v-else>
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <span style="color: red;">建议上传图片尺寸375px*210px</span>
          </a-col>
          <a-col span="24">
            <div class="flex"><span style="display:block;width: 4em;">链接：</span><a-input v-model="item.link" style="flex:1;" allowClear></a-input><a-button type="primary" style="margin-left: 20px;" @click="openLink(item)">选择链接</a-button></div>
            <div><a-button type="danger" @click="deleteItem(index, item)">删除</a-button></div>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button @click="onAdd"> 添加列表 </a-button>
      </a-form-model-item>
      <a-form-model-item label="当前排序">
        <a-input
          v-model="form.index"
          class="w220"
        />
      </a-form-model-item>
      <!-- <a-form-model-item label="是否显示">
        <a-checkbox v-model="form.is_visible"></a-checkbox>
      </a-form-model-item> -->
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit"> 保存 </a-button>
         <a-button type="danger" @click="onDelete" style="margin-left: 10px;"> 删除 </a-button>
      </a-form-model-item>
    </a-form-model>
    <chooseLink :visible="visible" @ok="handleOk"
      @cancel="handleCancel"></chooseLink>
  </section>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "carousel",
  data() {
    return {
      form: {
        speed: "",
        is_visible: false,
        panel_items: [],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {},
      visible: false,
      current: {},
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  components: {
    chooseLink: () => import("@/components/chooseLink"),
  },
  computed: {
    headers() {
      return { "X-CSRFToken": Cookies.get("csrftoken") };
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.form = {...this.form, ...this.info};
  },
  methods: {
    deleteItem (index, obj) {
      this.form.panel_items.splice(index, 1)
      
    },
     openLink (item) {
      this.visible = true
      this.current = item
    },
    handleOk (list) {
      this.current.link = list[0] ? list[0].link : ''

      this.visible = false
    },
    handleCancel () {
      this.visible = false
    },
    uploadChange(item,index ,{file}) {
      if (file.status == "done") {
        item.picture_url = file.response.image;
        item.picture = file.response.id;
        this.form.panel_items.splice(index, 1, item)
      }
    },
    onAdd() {
      if (this.form.panel_items.length > 0) {
        let last = this.form.panel_items[this.form.panel_items.length - 1];
        this.form.panel_items.push({
          index: last.index + 1,
          picture: "",
          link: "",
          picture_url: "",
        });
      } else {
        this.form.panel_items.push({
          index: 0,
          picture: "",
          link: "",
          picture_url: "",
        });
      }
    },
    onDelete () {
      this.$axios.delete('/carousel_modules/' + this.form.id + '/').then(() => {
        this.$emit('refreshDel')
      })
    },
    onSubmit() {
      console.log(this.form, "form");
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$axios.put("/carousel_modules/" + this.form.id + '/', this.form).then((res) => {
            console.log(res);
            this.$message.success("操作成功");
            this.$emit('refresh')
          });
          //   alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
        beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 2048;
      console.log(isLt500Kb, file.size / 1024);
      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(2048KB)，请修改后重新上传");
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="less">
</style>
